<script>
import Auth from "../../layouts/auth";
import appConfig from "../../../../app.config";
import errorHandler from '../../../helpers/errorHandler';
import { required } from "vuelidate/lib/validators";
import UAParser from 'ua-parser-js';



/**
 * Login component
 */
export default {
  created(){
    const parser = new UAParser();
    const result = parser.getResult();
    let browser = result.browser.name || 'no browser info';
    let device = result.device.model || 'no device info';
    let os = result.os.name || 'no operating system info';
    this.userAgent = device + ' - ' + os + ' - ' + browser;
  },
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }],
  },
  // all under construction here
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      passwordType: "password",
      userAgent: '',
    };
  },
  components: {
    Auth,
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
          const { username, password } = this;
          if (username && password) {
            await this.$store.dispatch("user/loginUser", {
              username: this.username.trim(),
              password: this.password,
              userAgent: this.userAgent
            }).then( async token =>{
              if( token.login == 'success'){
                
                  if(token.user.role == 1){
                    if(!token.user.update_data){
                      this.$router.push({
                        path: `/register/${token.user.id}/`
                      })
                      return;
                    }
                  }
                  this.tryingToLogIn = false;
                  this.isAuthError = false;
                  // Redirect to the originally requested page, or to the home page
                  this.$router.push(
                    this.$route.query.redirectFrom || {
                      path: "/home",
                    }
                  );
              } else {
                errorHandler.methods.errorLogin(token)
              }
              }).catch((error) => {
              errorHandler.methods.error(error);
              this.tryingToLogIn = false;
              this.authError = error ? error.response.data.detail["error message"] : "";
              this.isAuthError = true;
            });
            await this.$store.dispatch("notification/clear");
        }
      }
    },
    togglePassword(){
      if(this.passwordType == "password"){
        this.passwordType = "text";
      }else{
        this.passwordType = "password";
      }
    }
  },
};
</script>

<template>
  <Auth>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <div class="auth-logo">
                <router-link to="/" class="logo logo-dark text-center">
                  <span class="logo-lg">
                    <img
                      src="@/assets/images/logo-dark.png"
                      alt=""
                      height="120"
                    />
                  </span>
                </router-link>

                <router-link to="/" class="logo logo-light text-center">
                  <span class="logo-lg">
                    <img
                      src="@/assets/images/logo-light.png"
                      alt=""
                      height="22"
                    />
                  </span>
                </router-link>
              </div>
              <p class="text-muted mb-4 mt-3">
                Enter your username and password to access your panel.
              </p>
            </div>
            <h3 class="text-danger text-center font-weight-bold">Note :YOU CAN LOGIN ONLY WITH 2 DEVICES</h3>
            <form action="#" @submit.prevent="tryToLogIn">
              <b-alert
                :variant="notification.type"
                class="mt-3"
                v-if="notification.message"
                :show="notificationAutoCloseDuration"
                dismissible
                >{{ notification.message }}</b-alert
              >

              <b-alert
                variant="danger"
                class="mt-3"
                v-model="isAuthError"
                :show="notificationAutoCloseDuration"
                dismissible
                >{{ authError }}</b-alert
              >
              <div class="form-group mb-3">
                <label for="usernameid">Username</label>
                <input
                  class="form-control"
                  v-model="username"
                  type="text"
                  id="usernameid"
                  placeholder="Enter your username"
                  :class="{ 'is-invalid': submitted && $v.username.$error }"
                />
                <div
                  v-if="submitted && $v.username.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.username.required">Username is required.</span>
                </div>
              </div>

              <div class="form-group mb-3">
                <label for="password">Password</label>
                <div class="input-group input-group-merge">
                  <input
                    v-model="password"
                    :type="passwordType"
                    id="password"
                    class="form-control"
                    placeholder="Enter your password"
                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                  />

                  <div class="input-group-append" data-password="false">
                    <div class="input-group-text">
                      <span class="password-eye" @click="this.togglePassword"></span>
                    </div>
                  </div>
                  <div
                    v-if="submitted && !$v.password.required"
                    class="invalid-feedback"
                  >
                    Password is required.
                  </div>
                </div>
              </div>

              <!-- <div class="form-group mb-3">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="checkbox-signin"
                    checked
                  />
                  <label class="custom-control-label" for="checkbox-signin"
                    >Remember me</label
                  >
                </div>
              </div> -->

              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">
                  Log In
                </button>
              </div>
            </form>

            <!-- <div class="text-center">
              <h5 class="mt-3 text-muted">Sign in with</h5>
              <ul class="social-list list-inline mt-3 mb-0">
                <li class="list-inline-item">
                  <a
                    href="javascript: void(0);"
                    class="social-list-item border-purple text-purple"
                    ><i class="mdi mdi-facebook"></i
                  ></a>
                </li>
                <li class="list-inline-item">
                  <a
                    href="javascript: void(0);"
                    class="social-list-item border-danger text-danger"
                    ><i class="mdi mdi-google"></i
                  ></a>
                </li>
                <li class="list-inline-item">
                  <a
                    href="javascript: void(0);"
                    class="social-list-item border-info text-info"
                    ><i class="mdi mdi-twitter"></i
                  ></a>
                </li>
                <li class="list-inline-item">
                  <a
                    href="javascript: void(0);"
                    class="social-list-item border-secondary text-secondary"
                    ><i class="mdi mdi-github"></i
                  ></a>
                </li>
              </ul>
            </div> -->
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <!-- <p>
              <router-link to="/forgot-password" class="text-muted ml-1"
                >Forgot your password?</router-link
              >
            </p> -->
            <p class="text-muted">
              Don't have an account?
              <router-link
                to="/register"
                class="text-primary font-weight-medium ml-1"
                >Sign Up</router-link
              >
            </p>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Auth>
</template>
